h2.title {
    cursor: pointer;
    background-color: lightblue;
    padding: 0 5px;
}

h2.title div {
    float: right;
    font-size: 18px;
    margin: 10px 0;
    line-height: 14px;
}

button[type=submit] {
    float: right;
    margin: 10px;
}
