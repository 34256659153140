.day {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.course-box {
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  border-color: #979393;
  width: 390px;
}
.booking_status {
  border: 1px solid #4e9dba;
  border-radius: 5px;
  /* margin: 3px 10px; */
  display: inherit;
  justify-content: center;
}
.booking_status_1 {
  background-color: #81e9b4;
}
.booking_status_2 {
  background-color: #74cc9f;
}
.booking_status_3 {
  background-color: #ebdba0;
}
.booking_status_4 {
  background-color: #d45d5d;
}

.booking_status button {
  margin: 5px 0;
}
.booking_status div,
.booking_status div:hover {
  color: white;
  cursor: default;
}
