#root {
    justify-content: center;
    display: flex;
}

.App {
  /* display: grid; */
  /* align-items: center; */
  /* justify-content: center; */
  /* height: 100vh; */
    width: 800px;
    font-size: 14px;
}



.App-header {
  /* display: grid; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 10px 0;
}

.App-link {
  color: #61dafb;
}

