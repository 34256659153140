label {
    display: block;
    margin: 0.5rem 0 0.2rem;
}

.accordion-content {
    overflow: hidden;
    max-height: 100%;
}
.accordion-content[aria-expanded="false"] {
    max-height: 0px;
    transition: max-height 2s 1s;
    /* transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); */
    /* display: none; */
}
